import { render, staticRenderFns } from "./StateLeaderboard.vue?vue&type=template&id=09968c89&scoped=true&"
import script from "./StateLeaderboard.vue?vue&type=script&lang=js&"
export * from "./StateLeaderboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09968c89",
  null
  
)

export default component.exports