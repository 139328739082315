<template>
  <div>
    <div class="">
    <div class="row m-0 px-md-3 mm-0 mp-0">
      <!-- <div class="col-12"> -->
      <!-- </div> -->
        <!-- <h3 class="text-center txGold">Top Rankings</h3> -->
        <div class="col-12 col-lg-6 m-0 p-0" v-if="full" id="divFirst">
          <h3 class="text-center txGold mt-3">Top Rankings</h3>
            <apexchart width="100%" height="455px" type="treemap" :options="options" :series="options.series"></apexchart>
        </div>
        <div class="col-12 col-lg-6 mm-0 mp-0" v-if="full">
        <div class="row p-0 m-0">
          <div class="col-12"> <h3 class="text-center txGold"> Referral Statistics</h3>
        </div>
        <div class="col-12 col-lg-6 p-0 m-0 ">

  <div class="input-group mt-3">
  <input type="text" :class="{'text-white':$store.state.dark}" class="form-control" v-model="search" placeholder="Enter Referral ID">
  <div class="input-group-append">

    <button  id="basic-addon2" class="btn btn-outline-gold btbtbt border-top-bottom" style="padding:2px 13px;" @click="searchInChart(true)" :disabled="!search.length"><img src="@/assets/images/zoom.png" style="width: 35px; float: right;" alt=""> </button>
    <button class="btn btn-golds input-group-text" @click="searchInChart(false)">Clear</button>
  </div>
</div>
        </div>
        <div class="col-12 col-lg-6 m-0 p-0 ">
          <div class="float-md-right my-3">
            <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-golds" @click="prepare(24)" :class="{'active':range == 24}"> 24 Hours</button>
            <button type="button" class="btn btn-golds" @click="prepare(7)" :class="{'active':range == 7}"> 7 Days</button>
            <button type="button" class="btn btn-golds" @click="prepare(30)" :class="{'active':range == 30}"> 30 Days</button>
            <button type="button" class="btn btn-golds" @click="prepare(0)" :class="{'active':(range == 0 || range == 360)}"> All</button>
            
          </div>
          </div>
        </div>
        <div class="col-12 m-0 p-0">
            <h5 class="text-center">
                <span class="px-2" style="color:#b3b3b3;">Total Referrals <span class="circle"></span> </span>
                <span class="pr-2" style="color:#b99653">Verified <span class="circle"></span> </span>
                <span class="pr-2" style="color:#E0115F;">UnVerifed <span class="circle"></span> </span>
            </h5>
        </div>
        <div class="col-12 m-0 p-0" style="min-height:350px;">
            <div v-if="!chartLoading">
              <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart> 
            </div>
            <div v-else>
                <h3 class="txGold text-center py-5"> Loading....</h3>
            </div>
        </div>
        </div>
        </div>
        
            
         
       </div>
    </div>
    <div class="row p-0 m-0 justify-content-center" v-if="circlesReady" id="lastDiv">
      <div class="col-12 col-lg-3 col-md-4 px-3">
        <apexchart type="radialBar" height="350" :options="circleReferrals" :series="circleReferrals.series"></apexchart>
      </div>
      <div class="col-12 col-lg-3 col-md-4 px-3">
        <apexchart type="radialBar" height="350" :options="circleVerified" :series="circleVerified.series"></apexchart>
      </div>
      <div class="col-12 col-lg-3 col-md-4 px-3">
        <apexchart type="radialBar" height="350" :options="circleUnVerified" :series="circleUnVerified.series"></apexchart>
      </div>
      <div class="col-12">
        <!-- <h3 class="txGold link text-center" @click="full = true;" v-if="!full"> View More </h3> -->
      </div>
    </div>
    <div class="row p-0 m-0" v-if="full">
      <div class="col-12"> <h3 class="text-center txGold"> Register Statistics</h3> </div>
            <div class="col-12 m-0 p-0">
              <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-golds" @click="prepareReg(24)" :class="{'active':rangeReg == 24}"> Last 24 Hours</button>
            <button type="button" class="btn btn-golds" @click="prepareReg(7)" :class="{'active':rangeReg == 7}"> Last 7 Days</button>
            <button type="button" class="btn btn-golds" @click="prepareReg(30)" :class="{'active':rangeReg == 30}"> Last 30 Days</button>
            <button type="button" class="btn btn-golds" @click="prepareReg(0)" :class="{'active':(range == 0 || range == 360)}"> All</button>
            
          </div>
            </div>
            <div class="col-12 m-0 pl-0 pr-3">
              <h5 class="text-right">
                <span class="px-2" style="color:#b3b3b3;">Total Referrals <span class="circle"></span> </span>
                <span class="pr-2" style="color:#b99653">Verified <span class="circle"></span> </span>
                <span class="pr-2" style="color:#E0115F;">UnVerifed <span class="circle"></span> </span>
              </h5>
            </div>
        <div class="col-12 col-lg-6 mm-0 mp-0">
          <div class="row p-0 m-0 mm-0 mp-0">
      
            <div class="col-12 ml-0 pl-0" style="min-height:350px;">
                <div v-if="!chartLoadingReg">
                    <apexchart type="line" height="350" :options="chartOptionsReg" :series="seriesReg"></apexchart>
                </div>
                <div v-else>
                  <h3 class="txGold text-center py-5"> Loading....</h3>
                </div>
            </div> 
          </div>
        </div> 
        <div class="col-12 col-lg-6 mm-0 mp-0">
          <div class="row p-0 m-0">
            <div class="col-12 ml-0 pl-0" style="min-height:350px;">
                <div v-if="!chartLoadingRegTotal">
                    <apexchart type="line" height="350" :options="chartOptionsRegTotal" :series="seriesRegTotal"></apexchart>
                </div>
                <div v-else>
                  <h3 class="txGold text-center py-5"> Loading....</h3>
                </div>
            </div> 
          </div>
        </div> 
        <div class="col-12">
          <h3 class="txGold link text-center" @click="setFull(false)" v-if="full"> View Less </h3>
        </div>   
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import charts from './StateLeaderboard';
import VueApexCharts from 'vue-apexcharts'
// Vue.use(VueApexCharts)

// Vue.component('apexchart', VueApexCharts)
export default {
  components:{
    'apexchart': VueApexCharts
  },
data(){
    return {
      full:false,
      search:'',
      range:24,
      circlesReady:false,
      totalReward:500000,
      searchString:null,
      isSearch:false,
      rangeReg:24,
      DAYS:['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
      MONTHS:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
      chartLoading:false,
      chartLoadingReg:false,
      chartLoadingRegTotal:false,
      seriesReg:charts.seriesReg,
      seriesRegTotal:charts.seriesRegTotal,
      series:charts.series,
      chartOptionsReg:charts.chartOptionsReg,
      chartOptionsRegTotal:charts.chartOptionsRegTotal,
      chartOptions:charts.chartOptions,
      chartOptionsRegMob:charts.chartOptionsRegMob,
      chartOptionsRegTotalMob:charts.chartOptionsRegTotalMob,
      chartOptionsMob:charts.chartOptions,
      circleReferrals:charts.circleReferrals,
      circleVerified:charts.circleVerified,
      circleUnVerified:charts.circleUnVerified,
      ranks: this.ranksraw,
      heatmap: this.heatmapraw,

      
}
},
async mounted(){
  // await this.$store.dispatch("getHeatMapData")
    // await this.prepareOneDay()
    // await this.prepareOneDayReg()
    await this.prepareCircles()
},
methods:{
  setFull(value){
    location.href = "#lastDiv";
    this.full = value
  },
  prepareCircles(){
    this.circleReferrals.series = [this.totalData[0].perc.toFixed(0)]
    this.circleVerified.series = [this.totalData[1].perc.toFixed(0)]
    this.circleUnVerified.series = [this.totalData[2].perc.toFixed(0)]
    this.circleReferrals.plotOptions.radialBar.size = this.totalData[0].count 
    this.circleVerified.plotOptions.radialBar.size = this.totalData[1].count
    this.circleUnVerified.plotOptions.radialBar.size = this.totalData[2].count
    this.circlesReady = true
  },
  searchInChart(search){
    if (!search) {
      this.search = ''
    }
    switch (this.range) {
        case 24:
        this.prepareOneDay()
          break;
        case 7:
        this.prepareSevenDay()
          break;
        case 30:
        this.prepareMonthly(30)
          break;
        case 0:
        this.prepareMonthly(360)
          break;     
        default:
          break;
      }
    
  },
  getSearchString(obj){
    if (this.isAdmin) {
      return (
        obj.username.toLowerCase().trim() == this.search.toLowerCase().trim() ||
        obj.firstName.toLowerCase().trim() == this.search.toLowerCase().trim() ||
        obj.lastName.toLowerCase().trim() == this.search.toLowerCase().trim() ||
        obj.email.trim() == this.search.trim()
        ) ? obj._id : null;
    } else {
      return (obj.username.toLowerCase().trim() == this.search.toLowerCase().trim() ) ? obj._id : null;
    }
  },
  async prepareSearch(){
    this.isSearch = (this.search.length) ? true : false;
        this.searchString = null;
        if (this.search.length) {
          this.searchString = await this.promUsers.filter(this.getSearchString)
          console.log(this.searchString)
          this.searchString = (this.searchString.length) ? this.searchString[0]._id : null 
        }
        console.log("search criteria",this.isAdmin,"isa", this.isSearch,"isser", this.search,"sear","srstr", this.searchString)
  },
    async prepareOneDay(){

        this.range = 24
        let totalReferrals = {}
        let totalVerifs = {}
        let totalUnVerifs = {}
        let records = []
        let hours = []
        let elses = []
       await this.prepareSearch()
        let date = new Date()
            date.setDate(date.getDate() - 1)
            // console.log("calc date is", date)
            console.log("data before starting looppppppppppppppppppppppppppppppp", this.users)
        this.users.forEach(element => {
            let dd = new Date(element.date)
            if (this.isSearch) {
              if (dd > date ) {
                if (element.refAddress == this.searchString) {
                  records.push(element)
                let hs = dd.getHours()+':00'
                hours.push(hs)
                if (element.refAddress.length) {
                  totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1
                   totalVerifs[hs] = (element.verified) ? (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 1) : (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 0)  
                    totalUnVerifs[hs] = (!element.verified) ? (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 1) : (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 0)  
                }
                
                }
                
            }  
            } else {
              // console.log("Date", dd, "PDate", date)
              if (dd > date) {

                records.push(element)
                let hs = dd.getHours()+':00'
                hours.push(hs)
                if (element.refAddress.length) {
                  totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1
                   totalVerifs[hs] = (element.verified) ? (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 1) : (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 0)  
                    totalUnVerifs[hs] = (!element.verified) ? (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 1) : (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 0)  
                }
            } else{
              elses++
            }
            }
            
            
        });
        console.log("elses",elses,"vars after loop", "totalReferrals", totalReferrals, "totalVerifs", totalVerifs, "totalUnVerifs", totalUnVerifs, "records", records, "hours", hours)
        let uniq = [...new Set(hours)];
        // console.log("Total Staristics",totalReferrals, totalVerifs, totalUnVerifs, records)
        this.series[0].data = Object.values(totalReferrals)
        this.series[1].data = Object.values(totalVerifs)
        this.series[2].data = Object.values(totalUnVerifs)
        this.chartOptions.xaxis.categories = Object.keys(totalReferrals)
        this.chartOptions.xaxis.tickAmount = this.screen;
        this.chartOptions.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}
        console.log("results after generation this.chartOptions.xaxis", this.chartOptions.xaxis)
        this.reloadChart()
    },
    async prepareSevenDay(){
      
        this.range = 7
        let totalReferrals = {}
        let totalVerifs = {}
        let totalUnVerifs = {}
        let records = []
        let days = []
        let date = new Date()
       await this.prepareSearch()
            date.setDate(date.getDate() - 7)
            // console.log("calc date is", date)
        this.users.forEach(element => {
            let dd = new Date(element.date)
            
            if (this.isSearch) {
              if (dd > date && element.refAddress == this.searchString) {
                records.push(element)
                let hs = this.DAYS[dd.getDay()]
                // let hs = this.DAYS[dd.getDay()]+' '+ dd.getDate()+' '+this.MONTHS[dd.getMonth()]
                days.push(hs)
                if (element.refAddress.length) {
                  totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1
                   totalVerifs[hs] = (element.verified) ? (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 1) : (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 0)  
                    totalUnVerifs[hs] = (!element.verified) ? (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 1) : (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 0)  
                }
              }
            }else{
              if (dd > date) {
                records.push(element)
                let hs = this.DAYS[dd.getDay()]
                // let hs = this.DAYS[dd.getDay()]+' '+ dd.getDate()+' '+this.MONTHS[dd.getMonth()]
                days.push(hs)
                if (element.refAddress.length) {
                  totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1
                   totalVerifs[hs] = (element.verified) ? (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 1) : (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 0)  
                    totalUnVerifs[hs] = (!element.verified) ? (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 1) : (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 0)  
                }
            }
            } 
            
        });
        let uniq = [...new Set(days)];
        // console.log(totalReferrals, uniq)
        this.chartOptions.xaxis.tickAmount = this.screen;
        this.chartOptions.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}
        this.series[0].data = Object.values(totalReferrals)
        this.series[1].data = Object.values(totalVerifs)
        this.series[2].data = Object.values(totalUnVerifs)
        this.chartOptions.xaxis.categories = Object.keys(totalReferrals)
       
        this.reloadChart()
    },
    async prepareMonthly(range){
        this.range = range
        let totalReferrals = {}
        let totalVerifs = {}
        let totalUnVerifs = {}
        let records = []
        let days = []
        let date = new Date()
       await this.prepareSearch()
            date.setDate(date.getDate() - range)
            // console.log("calc date is", date)
        this.users.forEach(element => {
            let dd = new Date(element.date)
            
            if (this.isSearch) {
              if (dd > date && element.refAddress == this.searchString) {
                records.push(element)
                let hs = dd.getDate()+' '+this.MONTHS[dd.getMonth()]
                days.push(hs)
                if (element.refAddress.length) {
                  totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1
                   totalVerifs[hs] = (element.verified) ? (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 1) : (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 0)  
                    totalUnVerifs[hs] = (!element.verified) ? (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 1) : (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 0)  
                }
              }
            }else{
              if (dd > date) {
                records.push(element)
                let hs = dd.getDate()+' '+this.MONTHS[dd.getMonth()]
                days.push(hs)
                if (element.refAddress.length) {
                  totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1
                   totalVerifs[hs] = (element.verified) ? (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 1) : (((totalVerifs[hs]) ? (totalVerifs[hs]) : 0 ) + 0)  
                    totalUnVerifs[hs] = (!element.verified) ? (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 1) : (((totalUnVerifs[hs]) ? (totalUnVerifs[hs]) : 0 ) + 0)  
                }
            }
            }
            
        });
        this.series[0].data = Object.values(totalReferrals)
        this.series[1].data = Object.values(totalVerifs)
        this.series[2].data = Object.values(totalUnVerifs)
        this.chartOptions.xaxis.categories = Object.keys(totalReferrals)
        this.chartOptions.xaxis.tickAmount = this.screen;
        this.chartOptions.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}
        this.reloadChart()
    },
    reloadChart(){
        this.chartLoading = true;
        setTimeout(() => {
        this.chartLoading = false;
        }, 1000);
    },
    prepare(range){
        if (range == 24) {
            this.prepareOneDay()
        }else if (range == 7) {
            this.prepareSevenDay()
        }
        else if (range == 30) {
            this.prepareMonthly(30)
        }

        else if (range == 0) {
            this.prepareMonthly(360)
        } 
       },
    prepareOneDayReg(){
        this.rangeReg = 24
        let totalReferrals = {}
        let totalVerifs = {}
        let totalUnVerifs = {}
        let date = new Date()
        let hs = null
        date.setDate(date.getDate() - 1)
        this.users.forEach(element => {
            let dd = new Date(element.date)
            
            if (dd > date) {
                //  console.log(element)
            hs = dd.getHours()+':00'
                    totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1  
                    if (element.verified) {
                        totalUnVerifs[hs] = ((totalUnVerifs[hs]) ? totalUnVerifs[hs] : 0 ) + 0
                        totalVerifs[hs] = ((totalVerifs[hs]) ? totalVerifs[hs] : 0) + 1
                    }else{
                        totalVerifs[hs] = ((totalVerifs[hs]) ? totalVerifs[hs] : 0) + 0
                        totalUnVerifs[hs] = ((totalUnVerifs[hs]) ? totalUnVerifs[hs] : 0 ) + 1
                    }
            }
        });
        console.log("data in seriesReg",totalReferrals, totalVerifs, totalUnVerifs)
        let tTotalRefs = {}
        let tTotalVerif = {}
        let tTotalUnVerif = {}
        console.log(totalReferrals)
        let old = 0
        for (let key in totalReferrals) {
          old = old + totalReferrals[key]
          tTotalRefs[key] = old
        }
        old = 0
        for (let key in totalVerifs) {
          old = old + totalVerifs[key]
          tTotalVerif[key] = old
        }
        old = 0
        for (let key in totalUnVerifs) {
          old = old + totalUnVerifs[key]
          tTotalUnVerif[key] = old
        }
        // console.log(ttt)
        this.seriesReg[0].data = Object.values(totalReferrals)
        this.seriesReg[1].data = Object.values(totalVerifs)
        this.seriesReg[2].data = Object.values(totalUnVerifs)
        this.chartOptionsReg.xaxis.categories = Object.keys(totalReferrals)
        this.chartOptionsReg.xaxis.tickAmount = this.screen;
        this.chartOptionsReg.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}

        this.seriesRegTotal[0].data = Object.values(tTotalRefs)
        this.seriesRegTotal[1].data = Object.values(tTotalVerif)
        this.seriesRegTotal[2].data = Object.values(tTotalUnVerif)
        this.chartOptionsRegTotal.xaxis.categories = Object.keys(tTotalRefs)
        this.chartOptionsRegTotal.xaxis.tickAmount = this.screen;
        this.chartOptionsRegTotal.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}
        this.reloadChartReg()
    },
    prepareSevenDayReg(){
        this.rangeReg = 7
        let totalReferrals = {}
        let totalVerifs = {}
        let totalUnVerifs = {}
        let records = []
        let days = []
        let date = new Date()
            date.setDate(date.getDate() - 7)
            // console.log("calc date is", date)
        this.users.forEach(element => {
            let dd = new Date(element.date)
            if (dd > date) {
                records.push(element)
                let hs = this.DAYS[dd.getDay()]
                // let hs = this.DAYS[dd.getDay()]+' '+ dd.getDate()+' '+this.MONTHS[dd.getMonth()]
                days.push(hs)
                totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1  
                    if (element.verified) {
                        totalUnVerifs[hs] = ((totalUnVerifs[hs]) ? totalUnVerifs[hs] : 0 ) + 0
                        totalVerifs[hs] = ((totalVerifs[hs]) ? totalVerifs[hs] : 0) + 1  
                    }else{
                        totalVerifs[hs] = ((totalVerifs[hs]) ? totalVerifs[hs] : 0) + 0
                        totalUnVerifs[hs] = ((totalUnVerifs[hs]) ? totalUnVerifs[hs] : 0 ) + 1  
                    }
                
            }
        });
        let uniq = [...new Set(days)];
        // console.log(totalReferrals, uniq)
        let tTotalRefs = {}
        let tTotalVerif = {}
        let tTotalUnVerif = {}
        console.log(totalReferrals)
        let old = 0
        for (let key in totalReferrals) {
          old = old + totalReferrals[key]
          tTotalRefs[key] = old
        }
        old = 0
        for (let key in totalVerifs) {
          old = old + totalVerifs[key]
          tTotalVerif[key] = old
        }
        old = 0
        for (let key in totalUnVerifs) {
          old = old + totalUnVerifs[key]
          tTotalUnVerif[key] = old
        }
        // console.log(ttt)
        this.seriesReg[0].data = Object.values(totalReferrals)
        this.seriesReg[1].data = Object.values(totalVerifs)
        this.seriesReg[2].data = Object.values(totalUnVerifs)
        this.chartOptionsReg.xaxis.categories = Object.keys(totalReferrals)
        this.chartOptionsReg.xaxis.tickAmount = this.screen;
        this.chartOptionsReg.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}

        this.seriesRegTotal[0].data = Object.values(tTotalRefs)
        this.seriesRegTotal[1].data = Object.values(tTotalVerif)
        this.seriesRegTotal[2].data = Object.values(tTotalUnVerif)
        this.chartOptionsRegTotal.xaxis.categories = Object.keys(tTotalRefs)
        this.chartOptionsRegTotal.xaxis.tickAmount = this.screen;
        this.chartOptionsRegTotal.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}
        this.reloadChartReg()
        
    },
    prepareMonthlyReg(range){
        this.rangeReg = range
        let totalReferrals = {}
        let totalVerifs = {}
        let totalUnVerifs = {}
        let records = []
        let days = []
        let date = new Date()
            date.setDate(date.getDate() - range)
            // console.log("calc date is", date)
        this.users.forEach(element => {
            let dd = new Date(element.date)
            if (dd > date) {
                records.push(element)
                let hs = dd.getDate()+' '+this.MONTHS[dd.getMonth()]
                days.push(hs)
                totalReferrals[hs] = ((totalReferrals[hs]) ? (totalReferrals[hs]) : 0 ) + 1  
                    if (element.verified) {
                        totalUnVerifs[hs] = ((totalUnVerifs[hs]) ? totalUnVerifs[hs] : 0 ) + 0
                        totalVerifs[hs] = ((totalVerifs[hs]) ? totalVerifs[hs] : 0) + 1  
                    }else{
                        totalVerifs[hs] = ((totalVerifs[hs]) ? totalVerifs[hs] : 0) + 0
                        totalUnVerifs[hs] = ((totalUnVerifs[hs]) ? totalUnVerifs[hs] : 0 ) + 1  
                    }
                
                // console.log(dd.getdays()+':00' )
            }
        });
        let uniq = [...new Set(days)];
        let tTotalRefs = {}
        let tTotalVerif = {}
        let tTotalUnVerif = {}
        console.log(totalReferrals)
        let old = 0
        for (let key in totalReferrals) {
          old = old + totalReferrals[key]
          tTotalRefs[key] = old
        }
        old = 0
        for (let key in totalVerifs) {
          old = old + totalVerifs[key]
          tTotalVerif[key] = old
        }
        old = 0
        for (let key in totalUnVerifs) {
          old = old + totalUnVerifs[key]
          tTotalUnVerif[key] = old
        }
        // console.log(ttt)
        this.seriesReg[0].data = Object.values(totalReferrals)
        this.seriesReg[1].data = Object.values(totalVerifs)
        this.seriesReg[2].data = Object.values(totalUnVerifs)
        this.chartOptionsReg.xaxis.categories = Object.keys(totalReferrals)
        this.chartOptionsReg.xaxis.tickAmount = this.screen;
        this.chartOptionsReg.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}

        this.seriesRegTotal[0].data = Object.values(tTotalRefs)
        this.seriesRegTotal[1].data = Object.values(tTotalVerif)
        this.seriesRegTotal[2].data = Object.values(tTotalUnVerif)
        this.chartOptionsRegTotal.xaxis.categories = Object.keys(tTotalRefs)
        this.chartOptionsRegTotal.xaxis.tickAmount = this.screen;
        this.chartOptionsRegTotal.xaxis.labels = { show: true, rotate: 0, rotateAlways: false}
        this.reloadChartReg()
        
    },
    reloadChartReg(){
        this.chartLoadingReg = true;
        this.chartLoadingRegTotal = true;
        setTimeout(() => {
        this.chartLoadingReg = false;
        this.chartLoadingRegTotal = false;
        }, 1000);
    },
    prepareReg(range){
        if (range == 24) {
            this.prepareOneDayReg()
        }else if (range == 7) {
            this.prepareSevenDayReg()
        }
        else if (range == 30) {
            this.prepareMonthlyReg(30)
        }

        else if (range == 0) {
            this.prepareMonthlyReg(360)
        }    
}
},
props:{
  isAdmin:{
        type: Boolean,
        required: true,
        default: false
    },  
  ranksraw:{
        type: Object,
        required: true,
        default: () => {}
    },
    data:{
        type: Object,
        required: true,
        default: () => {}
    },
    heatmapraw:{
      type: Array,
        required: true,
        default: () => { return [] }
    },  
},
computed:{
    ...mapState(['user','refCount','totalReferred','refCountVerified','totalReferredVerified','promUsers','heatMapData']),
    screen(){
      return (window.innerWidth > 600) ? 7 : 5 ;
    },
    
    totalData(){
      let totalReferred = {count:this.data.totalsVerifs, perc:0}; 
      let totalVerified = {count:0, perc:0};
      let totalUnVerified = {count:0, perc:0};
      totalReferred.perc = ( this.data.totalsRefs / this.data.totals ) * 100
      totalVerified.perc = ( this.data.totalsVerifs / this.data.totals ) * 100
      totalUnVerified.perc = ( (this.data.totals - this.data.totalsVerifs) / this.data.totals ) * 100
      return [totalReferred, totalVerified, totalUnVerified]

    },
    users(){
      let users = []
      if (this.promUsers.length && Object.keys(this.ranks).length) {
        this.promUsers.forEach(element => {
        let elm  ={}
        elm.username = element.username
        if (this.refCountVerified[element._id]) {
         let num = (Number((this.refCountVerified[element._id] / this.totalReferredVerified) ) * this.totalReward).toFixed(2)
         elm.rank = this.ranks[num.toString()]
              } else {
                let n = '0.00'
                elm.rank = this.ranks[n]
              }
        
          elm.totalRefs = ((this.refCount[element._id]) ? this.refCount[element._id] : 0 )
          + 
          ((this.refCountVerified[element._id]) ? this.refCountVerified[element._id] : 0 )   
          elm.verifs = (this.refCountVerified[element._id]) ? this.refCountVerified[element._id] : 0   
          elm.unverifs = (this.refCount[element._id]) ? this.refCount[element._id] : 0
          if (this.refCountVerified[element._id]) {
            elm.esp = Number((this.refCountVerified[element._id] / this.totalReferredVerified)*100 ).toFixed(2)

          }else{
            elm.esp = 0
          }

        if (this.refCountVerified[element._id]) {
          elm.points = (Number((this.refCountVerified[element._id] / this.totalReferredVerified) ) * this.totalReward) * 20
        }else{
          elm.points = 0
        }
        if (this.refCountVerified[element._id]) {
          elm.esx  = (Number((this.refCountVerified[element._id] / this.totalReferredVerified) ) * this.totalReward)
        }else{
          elm.esx = 0
        }
        elm.date = element.date 
        elm.verified = element.emailVerified 
        elm.refAddress = element.refAddress 
        users.push(elm)
      });
      users = users.sort((a, b) => { return a.esx - b.esx;});

      return users;   
      } else {
        return users
      }
     
    },
    
    options() {
        if (this.data) {
            return {
                chart: {
      type: "treemap",
    },
    colors:['#b99653','#b3b3b3','#E0115F'],
    
tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: true,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
        fontFamily: undefined
      },
      onDatasetHover: {
          highlightDataSeries: false,
      },
      x: {
          show: false,
          formatter: undefined,
      },
      y: {
        show: true,
          formatter: (val) => val+' %',
          title: {
              formatter: (seriesName) => "Estimate Prelaunch Percentage",
          },
      },
      z: {
        show: true,
          formatter: (val) => val.toLocaleString("en-US") ,
          title: 'Points'
      },
      a: {
        show: true,
          formatter: (val) => val ,
          title: 'Verified Referrals'
      },
      marker: {
          show: false,
      },
     
  },
    series: [
      {
        data: [
        {
            a: this.data.users[0].verifiedRef,
            x: this.data.users[0].username,
            y: this.data.users[0].esPerc,
            z: this.data.users[0].points,
          },  
        {
            a: this.data.users[1].verifiedRef,
            x: this.data.users[1].username,
            y: this.data.users[1].esPerc,
            z: this.data.users[1].points,
          },
          {
            a: this.data.users[2].verifiedRef,
            x: this.data.users[2].username,
            y: this.data.users[2].esPerc,
            z: this.data.users[2].points,
          },
          {
            a: this.data.users[3].verifiedRef,
            x: this.data.users[3].username,
            y: this.data.users[3].esPerc,
            z: this.data.users[3].points,
          },
          {
            a: this.data.users[4].verifiedRef,
            x: this.data.users[4].username,
            y: this.data.users[4].esPerc,
            z: this.data.users[4].points,
          },
          {
            a: this.data.users[5].verifiedRef,
            x: this.data.users[5].username,
            y: this.data.users[5].esPerc,
            z: this.data.users[5].points,
          },
          {
            a: this.data.users[6].verifiedRef,
            x: this.data.users[6].username,
            y: this.data.users[6].esPerc,
            z: this.data.users[6].points,
          },
          {
            a: this.data.users[7].verifiedRef,
            x: this.data.users[7].username,
            y: this.data.users[7].esPerc,
            z: this.data.users[7].points,
          },
          {
            a: this.data.users[8].verifiedRef,
            x: this.data.users[8].username,
            y: this.data.users[8].esPerc,
            z: this.data.users[8].points,
          },
          {
            a: this.data.users[9].verifiedRef,
            x: this.data.users[9].username,
            y: this.data.users[9].esPerc,
            z: this.data.users[9].points,
          },
          

        ],
      },
    ]
            }
        }else{
            return {}
        }
    
  }
}
}
</script>

<style scoped>
</style>