let charts = {
    seriesReg: [{
        name: "Total",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    },
    {
        name: "Verified",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    },
    {
        name: "UnVerified",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }
  ],
  seriesRegTotal: [{
        name: "Total",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    },
    {
        name: "Verified",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    },
    {
        name: "UnVerified",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }
  ],
  series: [{
        name: "Total",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    },
    {
        name: "Verified",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    },
    {
        name: "UnVerified",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }
  ],
  chartOptionsReg: 
  {
    colors:['#b3b3b3','#b99653','#E0115F'],
    chart: 
    {
      stacked:false,
      dropShadow: 
      {
      enabled: false,
      enabledOnSeries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: '#000',
      opacity: 0.35
      },
      animations: 
      {
      enabled: true,
      easing: 'linear',
      speed: 2900,
      animateGradually:
       {
        enabled: true,
        delay: 550
      },
      dynamicAnimation: {
        enabled: true,
        speed: 2350
      }
      },
      height: 350,
      type: 'line',
      zoom: 
      {
        enabled: false
      }
    },
    xaxis:{tickAmount:10},
    dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        
        tooltip: {
  enabled: true,
  enabledOnSeries: undefined,
  shared: true,
  followCursor: true,
  intersect: false,
  inverseOrder: false,
  custom: undefined,
  fillSeriesColor: false,
  theme: false,
  style: {
    fontSize: '12px',
    fontFamily: undefined
  },
  onDatasetHover: {
      highlightDataSeries: false,
  },
  x: {
      show: false,
      formatter: undefined,
  },
  y: {
    show: true,
      formatter: (val) => val,
      title: {
          formatter: (seriesName) => seriesName,
      },
  },
  z: {
    show: true,
      formatter: (val) => val.toLocaleString("en-US") ,
      title: 'Points'
  },
  a: {
    show: true,
      formatter: (val) => val ,
      title: 'Verified Referrals'
  },
  marker: {
      show: true,
  },
 
},
        grid: {
          row: {
            colors: ['#b3b3b3','#b99653','#E0115F'], // takes an array which will be repeated on columns
            opacity: 0.05
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        
        }
  },
  chartOptionsRegMob: 
  {
    colors:['#b3b3b3','#b99653','#E0115F'],
    chart: 
    {
      stacked:false,
      dropShadow: 
      {
      enabled: false,
      enabledOnSeries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: '#000',
      opacity: 0.35
      },
      animations: 
      {
      enabled: true,
      easing: 'linear',
      speed: 2900,
      animateGradually:
       {
        enabled: true,
        delay: 550
      },
      dynamicAnimation: {
        enabled: true,
        speed: 2350
      }
      },
      height: 350,
      type: 'line',
      zoom: 
      {
        enabled: false
      }
    },
    xaxis:{tickAmount:5},
    dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        
        tooltip: {
  enabled: true,
  enabledOnSeries: undefined,
  shared: true,
  followCursor: true,
  intersect: false,
  inverseOrder: false,
  custom: undefined,
  fillSeriesColor: false,
  theme: false,
  style: {
    fontSize: '12px',
    fontFamily: undefined
  },
  onDatasetHover: {
      highlightDataSeries: false,
  },
  x: {
      show: false,
      formatter: undefined,
  },
  y: {
    show: true,
      formatter: (val) => val,
      title: {
          formatter: (seriesName) => seriesName,
      },
  },
  z: {
    show: true,
      formatter: (val) => val.toLocaleString("en-US") ,
      title: 'Points'
  },
  a: {
    show: true,
      formatter: (val) => val ,
      title: 'Verified Referrals'
  },
  marker: {
      show: true,
  },
 
},
        grid: {
          row: {
            colors: ['#b3b3b3','#b99653','#E0115F'], // takes an array which will be repeated on columns
            opacity: 0.05
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        
        }
  },
  chartOptionsRegTotal: 
  {
    colors:['#b3b3b3','#b99653','#E0115F'],
    chart: 
    {
      stacked:false,
      dropShadow: 
      {
      enabled: false,
      enabledOnSeries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: '#000',
      opacity: 0.35
      },
      animations: 
      {
      enabled: true,
      easing: 'linear',
      speed: 2900,
      animateGradually:
       {
        enabled: true,
        delay: 550
      },
      dynamicAnimation: {
        enabled: true,
        speed: 2350
      }
      },
      height: 350,
      type: 'line',
      zoom: 
      {
        enabled: false
      }
    },
    xaxis:{tickAmount:10},
    dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        
        tooltip: {
  enabled: true,
  enabledOnSeries: undefined,
  shared: true,
  followCursor: true,
  intersect: false,
  inverseOrder: false,
  custom: undefined,
  fillSeriesColor: false,
  theme: false,
  style: {
    fontSize: '12px',
    fontFamily: undefined
  },
  onDatasetHover: {
      highlightDataSeries: false,
  },
  x: {
      show: false,
      formatter: undefined,
  },
  y: {
    show: true,
      formatter: (val) => val,
      title: {
          formatter: (seriesName) => seriesName,
      },
  },
  z: {
    show: true,
      formatter: (val) => val.toLocaleString("en-US") ,
      title: 'Points'
  },
  a: {
    show: true,
      formatter: (val) => val ,
      title: 'Verified Referrals'
  },
  marker: {
      show: false,
  },
 
},
        grid: {
          row: {
            colors: ['#f3f3f3', '#b99653'], // takes an array which will be repeated on columns
            opacity: 0.05
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
  },
  chartOptionsRegTotalMob: 
  {
    colors:['#b3b3b3','#b99653','#E0115F'],
    chart: 
    {
      stacked:false,
      dropShadow: 
      {
      enabled: false,
      enabledOnSeries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: '#000',
      opacity: 0.35
      },
      animations: 
      {
      enabled: true,
      easing: 'linear',
      speed: 2900,
      animateGradually:
       {
        enabled: true,
        delay: 550
      },
      dynamicAnimation: {
        enabled: true,
        speed: 2350
      }
      },
      height: 350,
      type: 'line',
      zoom: 
      {
        enabled: false
      }
    },
    xaxis:{tickAmount:5},
    dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        
        tooltip: {
  enabled: true,
  enabledOnSeries: undefined,
  shared: true,
  followCursor: true,
  intersect: false,
  inverseOrder: false,
  custom: undefined,
  fillSeriesColor: false,
  theme: false,
  style: {
    fontSize: '12px',
    fontFamily: undefined
  },
  onDatasetHover: {
      highlightDataSeries: false,
  },
  x: {
      show: false,
      formatter: undefined,
  },
  y: {
    show: true,
      formatter: (val) => val,
      title: {
          formatter: (seriesName) => seriesName,
      },
  },
  z: {
    show: true,
      formatter: (val) => val.toLocaleString("en-US") ,
      title: 'Points'
  },
  a: {
    show: true,
      formatter: (val) => val ,
      title: 'Verified Referrals'
  },
  marker: {
      show: false,
  },
 
},
        grid: {
          row: {
            colors: ['#f3f3f3', '#b99653'], // takes an array which will be repeated on columns
            opacity: 0.05
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
  },
  chartOptions: {
        colors:['#b3b3b3','#b99653','#E0115F'],
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        xaxis:{tickAmount:10},
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        
        
        tooltip: {
  enabled: true,
  enabledOnSeries: undefined,
  shared: true,
  followCursor: true,
  intersect: false,
  inverseOrder: false,
  custom: undefined,
  fillSeriesColor: false,
  theme: false,
  style: {
    fontSize: '12px',
    fontFamily: undefined
  },
  onDatasetHover: {
      highlightDataSeries: false,
  },
  x: {
      show: false,
      formatter: undefined,
  },
  y: {
    show: true,
      formatter: (val) => val,
      title: {
          formatter: (seriesName) => seriesName,
      },
  },
  z: {
    show: true,
      formatter: (val) => val.toLocaleString("en-US") ,
      title: 'Points'
  },
  a: {
    show: true,
      formatter: (val) => val ,
      title: 'Verified Referrals'
  },
  marker: {
      show: false,
  },
 
},
        grid: {
          row: {
            colors: ['#f3f3f3', '#b99653'], // takes an array which will be repeated on columns
            opacity: 0.05
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
  },
  chartOptionsMob: {
    colors:['#b3b3b3','#b99653','#E0115F'],
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    xaxis:{tickAmount:5},
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    
    
    tooltip: {
enabled: true,
enabledOnSeries: undefined,
shared: true,
followCursor: true,
intersect: false,
inverseOrder: false,
custom: undefined,
fillSeriesColor: false,
theme: false,
style: {
fontSize: '12px',
fontFamily: undefined
},
onDatasetHover: {
  highlightDataSeries: false,
},
x: {
  show: false,
  formatter: undefined,
},
y: {
show: true,
  formatter: (val) => val,
  title: {
      formatter: (seriesName) => seriesName,
  },
},
z: {
show: true,
  formatter: (val) => val.toLocaleString("en-US") ,
  title: 'Points'
},
a: {
show: true,
  formatter: (val) => val ,
  title: 'Verified Referrals'
},
marker: {
  show: false,
},

},
    grid: {
      row: {
        colors: ['#f3f3f3', '#b99653'], // takes an array which will be repeated on columns
        opacity: 0.05
      },
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    }
  },
  circleReferrals : {
    colors:['#b3b3b3'],
    chart: {
            height: 280,
          type: "radialBar"
        },
        
        series: [67],
        
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "70%"
            },
           
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                color: "#b3b3b3",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
      
        stroke: {
          lineCap: "round",
        },
        labels: ["Referrals"]
  },
  circleVerified : {
    colors:['#b99653'],

        chart: {
          height: 280,
          type: "radialBar"
        },
        
        series: [67],
        
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "70%"
            },
           
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                color: "#b99653",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
      
        stroke: {
          lineCap: "round",
        },
        labels: ["Verified"]
  },
  circleUnVerified : {
    colors:['#E0115F'],

        chart: {
          height: 280,
          type: "radialBar"
        },
        
        series: [67],
        
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "70%"
            },
           
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                color: "#E0115F",
                fontSize: "30px",
                show: true
              }
            }
          }
        },
      
        stroke: {
          lineCap: "round",
        },
        labels: ["UnVerified"]
  }        
}

export default charts